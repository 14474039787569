/**
 * Google Universal Analytics (GA3) loader
 */

require("./functions")
require("./event_listeners")

// #if process.env.TIER === 'premium'
if (wpm.canLoadPremiumFeatures()) {
	require("./functions_premium")
	require("./event_listeners_premium")
}
// #endif
