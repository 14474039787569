/**
 * All event listeners
 * */


jQuery(document).on("wpmFbCapiEvent", function (event, eventData) {

	try {
		if (!wpmDataLayer.pixels.facebook.capi) return

		// Send data to pmw public API with fetch API
		// fetch(wpm.root + "pmw/v1/facebook/capi/event/", {
		// 	method     : "POST",
		// 	credentials: "same-origin",
		// 	headers    : {
		// 		"Content-Type": "application/json",
		// 	},
		// 	body       : JSON.stringify(eventData),
		// 	keepalive  : true,	// keep connection alive until request is finished
		// })
		// 	.then(response => response.json())
		// 	.then(message => {
		// 		if (!message.success) {
		// 			console.error(message)
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.error(error)
		// 	})

		// https://css-tricks.com/send-an-http-request-on-page-exit/
		const blob = new Blob([JSON.stringify(eventData)], { type: 'application/json; charset=UTF-8' });
		navigator.sendBeacon(wpm.root + "pmw/v1/facebook/capi/event/", blob);

	} catch (e) {
		console.error(e)
	}
})

