/**
 * Run the following on every page load
 */

jQuery(document).on("wpmLoadAlways", function () {

	try {

	} catch (e) {
		console.error(e)
	}
})
